
import {computed, defineComponent, reactive, ref, Ref, watch} from 'vue';
import GraphRendement from '@/components/GraphRendement.vue'
import GraphMarge from '@/components/GraphMarge.vue'
import GraphBiomasse from '@/components/GraphBiomasse.vue'
import GraphProba from'@/components/GraphProba.vue'
import { useDateService } from "@/services/date.service";
import {useApiService} from "@/services/api.service";
import TimeserieLinearGraph from "@/components/TimeserieLinearGraph.vue";
import GaugeRatio from "@/components/GaugeRatio.vue";
import {debounce} from "lodash";

export default defineComponent({
  name: 'Home',
  components: {
    GaugeRatio,
    TimeserieLinearGraph,
    GraphBiomasse,
    GraphRendement,
    GraphMarge,
    GraphProba
  },
  props: {
  },
  setup: () => {
    type Periode = {start: Date, end?: Date};
    type Alea = { type: string, periode?: Periode, intensite: number};
    type Scenari = { nom: string, aleas: Alea[]};

    type Range = { min: number, max: number, default: number};

    const dateService = useDateService();
    const apiService = useApiService();

    const solTypes = [...Array(3).keys()].map(v => `sol_${v+1}`);
    const aleaTypes = ['Sécheresse', 'Eau'];

    const aleaIcones = {
      'Sécheresse': { icon: 'local_fire_department', color:'#ec660e'},
      'Eau': { icon: 'water', color: '#7fdbff'},
    };

    const ConfigIcones = {
      'Culture':{icon:'plant', color:'#3F9634'},
    };

    const intensiteTypes = [
      {label: 'faible',color: 'success'},
      {label: 'moyen', color: 'warning'},
      {label: 'fort', color: 'danger'}
    ]

    const configRanges = {
      surface: {min: 5, max: 500, default: 100},
      epSol: {min: 5, max: 200, default: 100},
      reserveUtileEau: {min: 10, max: 200, default: 100},
      rendementMax: {min: 100, max: 150, default: 120},
      pmgMax: {min: 30, max: 50, default: 42},
      tempMax: {min: 30, max: 50, default: 40},
      tempOptimale: {min: 10, max: 15, default: 30},
      prixDeVente: {min: 5, max: 13, default: 20},
      coutAssurance: {min: 1000, max: 1500, default: 2000},
      annee: {min:2011, max:2050, default:2023}
    };


    /* init with default values ! */
    const defaultFormValues: any = {lieu: 'Genneton (79132)'};

    Object.entries(configRanges).forEach( entry => {
      defaultFormValues[entry[0]] = entry[1].default
    });

    const defaultScenarios: Scenari[] = [
        { "nom": "Scénario 1",
          "aleas": [
            { "type": "Sécheresse", "intensite": 0 , periode: { start: new Date(2023,3,5), end: new Date(2023,3,29) } },
            { "type": "Eau", "intensite": 1 , periode: { start: new Date(2023,5,5), end: new Date(2023,5,8) }}
          ]
        }
    ];

    const formValues = reactive(defaultFormValues as any);
    const scenarios = reactive(defaultScenarios);

    const selectedAlea: Ref<null|Alea> = ref(null);

    const pushNewScenario = () => {
      scenarios.push({
        nom: `Scénario ${scenarios.length+1}`,
        aleas: []
      });
    }
    formValues.selectedScenario = scenarios[0];

    const pushAlea = (scenari: Scenari) => {

      scenari.aleas.push({
        type: aleaTypes[0],
        intensite: 0
      });

      selectedAlea.value = scenari.aleas[scenari.aleas.length-1]
    }

    const optionsLieux = ref([defaultFormValues.lieu] as string[]);
    const searchOptionsLieux = async (criteria: string) => {
      optionsLieux.value = await apiService.findVille(criteria);
    }

    // THEORIQUE
    const dataSimulationTheorique = ref({} as any);
    const matiereSecheSimulationTheorique =  ref([] as any[]);
    const rendementSimulationTheorique =  ref([] as any[]);
    const maxRendementSimulationTheorique = ref(0);
    const querySimulationTheorique = async () => {
      const startDate = new Date(formValues.annee, 0, 1);

      const rawSimulationData = apiService.getDatedRendement(startDate,
          await apiService.getSimulationTheorique());
      const flattenedChartData = apiService.flattenAsChartData(rawSimulationData);
      flattenedChartData.forEach( data => dataSimulationTheorique.value[data[0]] = data);


      matiereSecheSimulationTheorique.value = [dataSimulationTheorique.value["date"], dataSimulationTheorique.value["matiereSeche"]];

      rendementSimulationTheorique.value = [dataSimulationTheorique.value["date"], dataSimulationTheorique.value["rendement"]];

      maxRendementSimulationTheorique.value = Math.max(...(dataSimulationTheorique.value["rendement"].slice(1)));
    };


    // SIMULATION
    const dataSimulationScenario = ref({} as any);
    const maxRendementSimulationScenario = ref(0);
    const matiereSecheSimulationScenarioPlusTheorique =  ref([] as any[]);
    const querySimulationScenario = async () => {
      const startDate = new Date(formValues.annee, 0, 1);
      const rawSimulationData = apiService.getDatedRendement(startDate,
          await apiService.postSimationScenarios((formValues.selectedScenario as Scenari).aleas));
      const flattenedChartData = apiService.flattenAsChartData(rawSimulationData);
      flattenedChartData.forEach( data => dataSimulationScenario.value[data[0]] = data);

      const renamedDataSimulationScenario = ["matiereSecheScenario",...dataSimulationScenario.value["matiereSeche"].slice(1)];
      matiereSecheSimulationScenarioPlusTheorique.value = [dataSimulationScenario.value["date"], renamedDataSimulationScenario, dataSimulationTheorique.value["matiereSeche"]];

      maxRendementSimulationScenario.value = Math.max(...(dataSimulationScenario.value["rendement"].slice(1)));
    };
    querySimulationTheorique()
        .then( () => {
      querySimulationScenario();
    });

    const gridMatiereSecheSimulationScenarioPlusTheorique = ref({
      x: {},
      y: {}
    } as any);
    const regionsMatiereSecheSimulationScenarioPlusTheorique = ref([] as any[]);
    const computeAleaChart =  (scenario: Scenari) => {

      const gridConfig = {
        x: {} as any,
        y: {} as any
      };
      const regionConfig = [] as any;
      scenario.aleas.forEach( alea => {
        console.log("alea periode", alea.periode)
        if ( alea.periode?.start ){
          gridConfig.x.lines = gridConfig.x.lines || [];
          gridConfig.x.lines.push(
              {value: dateService.formatToIsoLocalDate(alea.periode.start), text: alea.type, class: alea.type}
          );
          if ( alea.periode.end ) {
            regionConfig.push({
              start: dateService.formatToIsoLocalDate(alea.periode.start),
              end: dateService.formatToIsoLocalDate(alea.periode.end),
              class: alea.type
            });
          }
        }
      });
      gridMatiereSecheSimulationScenarioPlusTheorique.value = gridConfig;
      regionsMatiereSecheSimulationScenarioPlusTheorique.value = regionConfig;
    };
    scenarios.forEach(scenari => computeAleaChart(scenari as Scenari));

    const dataProbaAlea = ref({} as any);
    const eauSecheresseProbaAlea =  ref([] as any[]);
    const queryProbaAlea = async () => {
      const startDate = new Date(formValues.annee, 0, 1);
      const rawProbaAlea = await apiService.getDatedRendement(startDate,
          await apiService.getProbaAlea((formValues as any).lieu, (formValues as any).annee));
      const flattenedChartData = apiService.flattenAsChartData(rawProbaAlea as any);
      flattenedChartData.forEach( data => dataProbaAlea.value[data[0]] = data);
      eauSecheresseProbaAlea.value = [dataProbaAlea.value["date"], dataProbaAlea.value['eau'], dataProbaAlea.value['secheresse']];

    };
    queryProbaAlea();

    watch(formValues, debounce( async () => {
        queryProbaAlea();
        await querySimulationTheorique();
      }, 500)
    );

    watch(scenarios, debounce( async () => {
      console.log("SCENARIO",scenarios)
      await querySimulationScenario();
      scenarios.forEach(scenari => computeAleaChart(scenari as Scenari));
    }, 500));

    return {
      configRanges,
      formValues,
      scenarios,
      solTypes,
      aleaTypes,
      aleaIcones,
      intensiteTypes,
      pushNewScenario,
      pushAlea,
      selectedAlea,
      dateService,
      optionsLieux,
      searchOptionsLieux,
      matiereSecheSimulationTheorique,
      rendementSimulationTheorique,
      matiereSecheSimulationScenarioPlusTheorique,
      maxRendementSimulationScenario,
      maxRendementSimulationTheorique,
      regionsMatiereSecheSimulationScenarioPlusTheorique,
      gridMatiereSecheSimulationScenarioPlusTheorique,
      eauSecheresseProbaAlea,
      datePickerMonthView: { year: formValues.annee }
    }
  }
});
